import React from "react";
import Footer from "src/components/Footer_es";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/es/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/es/nuestros-productos/" target="_self">
                      Nuestros Productos
                    </a>
                    <ul>
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">
                          After Sun
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">Galeria</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/es/nuestra-historia/" target="_self">
                      Nuestra historia
                    </a>
                  </li>
                  <li>
                    <a className="es__header"href="/es/conozca-el-sol/" target="_self">
                      Conozca el sol
                    </a>
                  </li>
                   {/*<li>
                    <a className="es_header" href="/es/donde-has-estado/" target="_self">
                      Dónde has estado 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Países
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/legal-notice/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/legal-notice/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/aviso-legal/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/aviso_legal/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/legal-notice/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/legal-notice/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h2>AVISO LEGAL</h2>
                <p>
                  Todo el contenido de este sitio de Internet pertenece o está
                  controlado por JNTL Consumer Health (Spain), S.L. el cual está
                  protegido por leyes a nivel mundial. Ud. puede descargar
                  contenido solo para su uso personal y motivos no comerciales,
                  no está permitido realizar modificiaciones u otras
                  reproducciones del contenido.
                  <br />
                  El contenido no puede ser copiado o utilizado en ningún modo.
                </p>
                <p>
                  Los propietarios de este sitio harán todos los esfuerzos
                  razonables para incluir información actualizada y precisa en
                  este sitio de Internet, pero no hacen ninguna representación,
                  garantías o seguridades en cuanto a la exactitud, vigencia o
                  integridad de la información proporcionada. Los propietarios
                  de este sitio no se hacen responsables de ningún daño o
                  perjuicio que pueda ocasionar su acceso a, o la imposibilidad
                  de acceder a este sitio de Internet, o de su confianza en la
                  información proporcionada en este sitio de Internet. Este
                  sitio es para residentes de España.
                </p>
                <p>
                  Este sitio de Internet puede proporcionar enlaces o
                  referencias a otros sitios, sin embargo los propietarios de
                  este sitio no tienen ninguna responsabilidad por el contenido
                  de dichos sitios y no será responsable por ningún daño o
                  lesiones derivados de ese contenido. Los enlaces a otros
                  sitios son proporcionados exclusivamente para la conveniencia
                  a los usuarios de este sitio de Internet.
                </p>
                <p>
                  Las marcas comerciales, marcas de servicio, nombres
                  comerciales, imagen comercial y productos en este sitio de
                  Internet están protegidos en España y a nivel internacional.
                  Ningún uso de cualquiera de ellos puede hacerse sin la previa
                  autorización por escrito de los propietarios de este sitio,
                  excepto para identificar los productos o servicios de la
                  empresa.
                </p>
                <p>
                  Cualquier información personal identificable en comunicaciones
                  electrónicas a través este sitio de Internet se rige por la{" "}
                  <a href="/es/legal/politicia-de-privacidad/">
                    Política de privacidad
                  </a>{" "}
                  de este sitio. Los propietarios de este sitio estarán en
                  libertad de usar o copiar toda la información contenida en
                  cualquiera de dichas comunicaciones, incluyendo ideas,
                  invenciones, conceptos, técnicas o know-how aquí revelados,
                  para cualquier propósito. Tales propósitos pueden incluir
                  publicación a terceras personas, mercancías o el desarrollo,
                  fabricación y / o comercialización o servicios.
                </p>
                <p>
                  El remitente de cualquier comunicación a través este sitio de
                  Internet o a través de cualquier otro metodo hacia los
                  propietarios de este sitio, será responsable por el contenido
                  y la información contenida en la comunicación, incluyendo su
                  veracidad y exactitud.
                </p>
                <p>
                  JNTL Consumer Health (Spain), S.L. responsable de este sitio web, pone
                  a disposición de los usuarios el presente documento para dar
                  cumplimiento a las obligaciones dispuestas en la Ley 34/2002,
                  de Servicios de la Sociedad de la Información y del Comercio
                  Electrónico. Los usuarios consienten expresamente que el uso
                  de este sitio web se realiza bajo su exclusiva responsabilidad
                  y, asimismo, se comprometen a no dañar la imagen, reputación y
                  cualesquiera derechos de JNTL Consumer Health (Spain), S.L. ni ninguna
                  entidad de su grupo al usar este sitio web.
                </p>
                <p>
                  La utilización de esta página web así como las de los
                  subdominios y/o directorios (en adelante conjuntamente
                  denominados el "Sitio") queda sometida tanto a las presentes
                  Condiciones Generales de Uso, como a las condiciones
                  particulares propias (en adelante, las "Condiciones
                  Particulares") que, según los casos, puedan regir la
                  utilización de determinados servicios ofertados en la misma.
                  Por lo tanto, con anterioridad a la utilización de dichos
                  servicios, el Usuario también habrá de leer atentamente tanto
                  este Aviso Legal como, en su caso, las correspondientes
                  Condiciones Particulares. Asimismo, la utilización del Sitio
                  se encuentra sometida igualmente a todos los avisos,
                  reglamentos de uso e instrucciones, que puestos en
                  conocimiento del Usuario por JNTL Consumer Health (Spain), S.L.
                  sustituyan, completen y/o modifique las presentes Condiciones
                  Generales de Uso. Por el mero uso del Sitio o de cualquiera de
                  los sitios incluidos en la página web el Usuario manifiesta su
                  aceptación sin reservas de las presentes Condiciones Generales
                  de Uso. Este sitio web está dirigido a los residentes en
                  España.
                </p>
                <p>
                  Por ello, si las consideraciones detalladas en este Aviso
                  Legal no son de su conformidad, rogamos no haga uso del Sitio,
                  ya que cualquier uso que haga del mismo o de los servicios y
                  contenidos en él implicará la aceptación de los términos
                  legales recogidos en este texto.
                </p>
                <p>
                  JNTL Consumer Health (Spain), S.L. se reserva el derecho a realizar
                  cambios en el Sitio sin previo aviso, con el objeto de
                  actualizar, corregir, modificar, añadir o eliminar los
                  contenidos del Sitio o de su diseño. Los contenidos y
                  servicios del Sitio se actualizan periódicamente. Debido a que
                  la actualización de la información no es inmediata, le
                  sugerimos que compruebe siempre la vigencia y exactitud de la
                  información, servicios y contenidos recogidos en el Sitio.
                  Asimismo, las condiciones y términos que se recogen en el
                  presente Aviso Legal pueden variar, por lo que le invitamos a
                  que revise estos términos cuando visite de nuevo el Sitio.
                </p>
                <p>
                  Los propietarios de este sitio web tratarán, en la medida de
                  lo posible, de que la información publicada esté actualizada y
                  sea correcta, pero no declaran ni garantizan que esta sea
                  precisa, completa y esté puesta al día.
                </p>
                <p>
                  JNTL Consumer Health (Spain), S.L. no será responsable de ningún daño
                  o lesión producidos a causa del acceso o la incapacidad de
                  acceder a este Sitio web, o por la confianza del usuario en la
                  información publicada en el Sitio. En este sentido, JNTL Consumer Health (Spain), S.L. ha concluido todos los contratos
                  necesarios para la continuidad de su Sitio y realizará sus
                  mejores esfuerzos para que el mismo no sufra interrupciones,
                  pero no puede garantizar la ausencia de fallos tecnológicos,
                  ni la permanente disponibilidad del Sitio y de los servicios
                  contenidos en él.
                </p>
                <p> </p>
                <h2>Condición de usuario</h2>
                <p>
                  La utilización del Sitio atribuye la condición de Usuario del
                  Sitio (en adelante, el "Usuario") e implica la aceptación
                  plena y sin reservas de todas y cada una de las disposiciones
                  incluidas tanto en el presente Aviso Legal como en la{" "}
                  <a href="/es/legal/politicia-de-privacidad/">
                    Política de Privacidad
                  </a>
                  .
                </p>
                <p> </p>
                <h2>Responsabilidades del Usuario</h2>
                <p>
                  El Usuario se compromete a utilizar los Servicios del Sitio de
                  acuerdo con los términos expresados en el presente Aviso
                  Legal, siendo responsable de su uso correcto. Sin perjuicio de
                  lo que se expone a continuación, el Usuario se obliga a no
                  utilizar el Sitio para la prestación de servicios, la
                  realización de actividades publicitarias o de explotación
                  comercial.
                </p>
                <p> </p>
                <h2>Veracidad de los datos proporcionados por el Usuario</h2>
                <p>
                  Algunos de los servicios ofertados requieren el previo
                  registro del Usuario, para cuya utilización deberá darse de
                  alta como Usuario de la web de JNTL Consumer Health (Spain), S.L.
                  debiendo para ello leer y aceptar expresamente -en el momento
                  anterior a efectuar su alta en el servicio- las condiciones de
                  registro y la{" "}
                  <a href="/es/legal/politicia-de-privacidad/">
                    Política de Privacidad
                  </a>
                  .
                </p>
                <p>
                  Toda la información que facilite el Usuario deberá ser veraz.
                  A estos efectos, el Usuario garantiza la autenticidad de todos
                  aquellos datos que comunique como consecuencia de la
                  cumplimentación de los formularios necesarios para el registro
                  y acceso a determinados servicios. Será asimismo
                  responsabilidad del Usuario mantener toda la información
                  facilitada a JNTL Consumer Health (Spain), S.L. permanentemente
                  actualizada de forma que responda, en cada momento, a la
                  situación real del Usuario. En todo caso el Usuario será el
                  único responsable de las manifestaciones falsas o inexactas
                  que realice y de los perjuicios que cause a JNTL Consumer Health (Spain), S.L. o a terceros por la información que facilite.
                </p>
                <p> </p>
                <h2>Uso del Sitio</h2>
                <p>
                  Los propietarios de este sitio están autorizados a utilizar,
                  reproducir o copiar toda la información transmitida a través
                  de este sitio web, incluyendo ideas, inventos, conceptos,
                  técnicas o conocimientos revelados para los fines que deseen
                  (información a terceras partes y/o creación, fabricación y/o
                  marketing de productos y servicios a nivel mundial sin
                  retribución alguna). El usuario acepta ejecutar, reconocer y
                  entregar a JNTL Consumer Health (Spain), S.L. todos los documentos
                  necesarios o útiles para obtener, perfeccionar, hacer respetar
                  o mantener todos los derechos de reproducción o cualquier otro
                  derecho de propiedad intelectual o industrial sobre la
                  referida información. En particular, el Usuario se compromete
                  a abstenerse de:
                </p>
                <p>
                  i. reproducir, copiar, distribuir, poner a disposición o de
                  cualquier otra forma comunicar públicamente, transformar o
                  modificar los Contenidos, a menos que se cuente con la
                  autorización del titular de los correspondientes derechos o
                  ello resulte legalmente permitido;
                </p>
                <p>
                  ii. suprimir, manipular o de cualquier forma alterar el
                  ¨copyright¨ y demás datos identificativos de la reserva de
                  derechos de JNTL Consumer Health (Spain), S.L. o de sus titulares.
                  <br />
                  JNTL Consumer Health (Spain), S.L. no será en ningún caso responsable
                  del contenido de las comunicaciones o informaciones que sean
                  remitidas o intercambiadas por los usuarios del Sitio.
                </p>
                <p>
                  Todo aquel que envíe comunicaciones a este sitio web o a sus
                  propietarios será responsable del contenido de éstas, también
                  en lo que se refiere a su veracidad y precisión, no
                  haciéndose, por tanto, responsable JNTL Consumer Health (Spain), S.L.
                  de la información y contenidos introducidos por terceros. No
                  obstante y en cumplimiento de lo dispuesto en el art. 11 y 16
                  de la Ley 34/2002, de Servicios de la Sociedad de la
                  Información y del Comercio Electrónico, JNTL Consumer Health (Spain), S.L.. se pone a disposición de todos los usuarios, autoridades
                  y fuerzas de seguridad para colaborar de forma activa en la
                  retirada o en su caso bloqueo de todos aquellos contenidos que
                  pudieran afectar o contravenir la legislación nacional, o
                  internacional, derechos de terceros o la moral y el orden
                  público. En caso de que el usuario considere que existe en el
                  sitio web algún contenido que pudiera ser susceptible de esta
                  clasificación, se ruega lo notifique de forma inmediata al
                  propietario del sitio web.
                </p>
                <p>
                  JNTL Consumer Health (Spain), S.L. no se responsabiliza del uso que
                  pueda darse ni de los contenidos que puedan ser enviados y/o
                  visualizados a través del sistema de mensajería instantánea
                  (chat) o de cualquier otro medio. Ninguna de estas
                  informaciones y/o contenidos podrán ser entendidos como
                  contenidos de JNTL Consumer Health (Spain), S.L. ni en el sentido de
                  que JNTL Consumer Health (Spain), S.L. tenga responsabilidad alguna
                  sobre los mismos. No obstante todo lo anterior, se reserva el
                  derecho de retirar el acceso en caso de tener conocimientos de
                  un uso indebido de la web, entendiéndose por tal la
                  introducción de contenidos ilegales o contrarios a la moral y
                  al orden público, así como en caso de hacerse en uso de la
                  misma que sea o pueda ser perjudicial para la imagen de la
                  compañía.
                </p>
                <p>
                  El Usuario se obliga a no utilizar el Sitio ni los servicios
                  ofertados en o a través del mismo para la realización de
                  actividades contrarias a las leyes, a la moral, al orden
                  público, lesivas de los derechos e intereses de terceros o que
                  de cualquier otra forma puedan dañar, inutilizar, sobrecargar,
                  deteriorar o impedir la normal utilización del Sitio.
                </p>
                <p> </p>
                <h2>
                  Derechos de propiedad intelectual y de propiedad industrial
                </h2>
                <p>
                  Este sitio web, incluyendo a título enunciativo pero no
                  limitativo su contenido, programación, edición, compilación y
                  demás elementos necesarios para su funcionamiento, los
                  diseños, logotipos, texto y/o gráficos son propiedad y/o son
                  usados lícitamente bajo licencia de JNTL Consumer Health (Spain), S.L.
                  y está protegido por las leyes internacionales y/o nacionales
                  en materia de derechos de propiedad intelectual e industrial.
                  Se permite descargar el contenido sólo para un uso personal
                  sin fines comerciales, pero no se cede ningún derecho de
                  propiedad intelectual o industrial sobre este sitio web o
                  cualesquiera de sus partes integrantes, prohibiéndose
                  expresamente su modificación, transformación, reproducción
                  total o parcial, uso, explotación, comunicación pública,
                  distribución, comercialización o utilización de cualquier otra
                  forma para otros fines distintos del mero uso personal sin
                  fines comerciales, sin la autorización previa por escrito de
                  los propietarios del sitio y de los titulares de los referidos
                  derechos de propiedad intelectual o industrial. Usted podrá
                  bajarse el contenido de la Web sólo para su uso personal y sin
                  propósito comercial alguno, pero no podrá modificarlo o
                  reproducirlo.
                </p>
                <p>
                  Los usuarios se comprometen a respetar los derechos de
                  propiedad de este sitio web y de los derechos de propiedad
                  intelectual e industrial sobre el mismo, sean de JNTL Consumer Health (Spain), S.L. o de terceros. Las marcas registradas, las marcas
                  de servicios, las imágenes comerciales y los productos que
                  aparecen en este sitio web están protegidos en España y a
                  nivel internacional. Queda prohibido su uso sin la
                  autorización previa por escrito de los propietarios del sitio,
                  salvo si es para identificar los productos y servicios de la
                  empresa.
                </p>
                <p>
                  JNTL Consumer Health (Spain), S.L. declara su respeto a los derechos
                  de propiedad intelectual e industrial de terceros; por ello,
                  si considera que este sitio pudiera estar violando sus
                  derechos, rogamos se ponga en contacto con JNTL Consumer Health (Spain), S.L.. en la dirección incluida al final de este aviso
                  legal.
                </p>
                <p> </p>
                <h2>Links o hiperenlaces</h2>
                <p>
                  Este sitio web puede contener enlaces o referencias a otros
                  sitios, pero los propietarios de este sitio web no tienen
                  responsabilidad alguna por el contenido de los sitios de
                  terceros y no serán responsables por eventuales daños o
                  perjuicios provocados por dicho contenido. Los enlaces a otros
                  sitios tienen como único objeto facilitar la navegación a los
                  usuarios de este sitio web.
                </p>
                <p>
                  Cualesquiera terceros interesados en incluir un enlace o
                  referencia a este sitio web debe contar con la autorización
                  expresa de sus propietarios. Adicionalmente, dichos enlaces
                  deberán respetar las siguientes condiciones: únicamente podrán
                  realizarse enlaces con la Home Page o página principal de esta
                  web;
                  <br />
                  el establecimiento del enlace no supondrá ningún tipo de
                  acuerdo, contrato, patrocinio ni recomendación por parte de
                  JNTL Consumer Health (Spain), S.L.. de la página que realiza el enlace
                  salvo que así se derive de la correspondiente relación
                  contractual entre las partes.
                </p>
                <p>
                  En cualquier momento, JNTL Consumer Health (Spain), S.L. podrá retirar
                  la autorización mencionada en el párrafo anterior, sin
                  necesidad de alegar causa alguna. En tal caso, la página que
                  haya realizado el enlace deberá proceder a su inmediata
                  supresión, tan pronto como reciba la notificación de la
                  revocación de la autorización por parte de JNTL Consumer Health (Spain), S.L.
                </p>
                <p> </p>
                <h2>Frames</h2>
                <p>
                  JNTL Consumer Health (Spain), S.L. prohíbe expresamente la realización
                  de ¨framings¨ o la utilización por parte de terceros de
                  cualesquiera otros mecanismos que alteren el diseño,
                  configuración original o contenidos de su Sitio.
                </p>
                <p> </p>
                <h2>Privacidad</h2>
                <p>
                  Toda información personal comunicada electrónicamente al sitio
                  web está sujeta a la{" "}
                  <a href="/es/legal/politicia-de-privacidad/">
                    política de privacidad
                  </a>{" "}
                  de éste. JNTL Consumer Health (Spain), S.L. se encuentra profundamente
                  comprometido con el cumplimiento de la normativa española de
                  protección de datos de carácter personal, y garantiza el
                  cumplimiento íntegro de las obligaciones dispuestas, así como
                  la implementación de las medidas de seguridad dispuestas en el
                  art. 9 de la Ley 15/1999, de Protección de Datos de Carácter
                  Personal (LOPD) y en el Reglamento de Desarrollo de la LOPD.
                </p>
                <p>
                  Este sitio web puede utilizar cookies (pequeños archivos de
                  información que el servidor envía al ordenador de quien accede
                  a la página) para llevar a cabo determinadas funciones que son
                  consideradas imprescindibles para el correcto funcionamiento y
                  visualización del sitio. Las cookies utilizadas en el sitio
                  web tienen, en todo caso, carácter temporal con la única
                  finalidad de hacer más eficaz su transmisión ulterior y
                  desaparecen al terminar la sesión del usuario. En ningún caso
                  se utilizarán las cookies para recoger información de carácter
                  personal. Si a pesar de ello, Ud. no desea que se instale en
                  su disco duro una ¨cookie¨, solicitamos configure el navegador
                  de su ordenador para no recibirlas. Sin embargo, le hacemos
                  notar que, en todo caso, la calidad de funcionamiento de la
                  página web puede disminuir.
                </p>
                <p> </p>
                <h2>Responsabilidades de JNTL Consumer Health (Spain), S.L.</h2>
                <p>
                  Uso incorrecto del Sitio: JNTL Consumer Health (Spain), S.L. ha creado
                  este Sitio para la difusión de su actividad y para facilitar
                  el acceso a sus servicios, pero no puede controlar la
                  utilización del mismo de forma distinta a la prevista en la
                  presente Advertencia Legal; por tanto el acceso al Sitio y el
                  uso correcto de la información contenida en el mismo son
                  responsabilidad de quien realiza estas acciones, no siendo
                  responsable JNTL Consumer Health (Spain), S.L. por el uso incorrecto,
                  ilícito o negligente que del mismo pudiere hacer el Usuario,
                  ni del conocimiento que puedan tener terceros no autorizados
                  de la clase, condiciones, características y circunstancias del
                  uso que los Usuarios hacen del Sitio y de los servicios.
                  Asimismo, JNTL Consumer Health (Spain), S.L. tampoco será responsable
                  de los perjuicios de toda naturaleza que puedan deberse a la
                  suplantación de la personalidad de un tercero efectuada por un
                  Usuario en cualquier clase de comunicación realizada a través
                  del Sitio.
                </p>
                <p>
                  Utilización de los contenidos: JNTL Consumer Health (Spain), S.L.
                  facilita todos los contenidos de su Sitio de buena fe y
                  realizará sus mejores esfuerzos para que los mismos estén
                  permanentemente actualizados y vigentes; no obstante, JNTL Consumer Health (Spain), S.L. no puede asumir responsabilidad alguna
                  respecto al uso o acceso que realicen los Usuarios fuera del
                  ámbito al que se dirige el Sitio, cuya responsabilidad final
                  recaerá sobre el Usuario.
                </p>
                <p>
                  Virus: JNTL Consumer Health (Spain), S.L. se compromete a aplicar
                  todas las medidas necesarias para intentar garantizar al
                  Usuario la ausencia de virus, gusanos, caballos de Troya y
                  elementos similares en su Sitio. No obstante, estas medidas no
                  son infalibles y, por ello, no puede asegurar totalmente la
                  ausencia de dichos elementos nocivos. En consecuencia, JNTL Consumer Health (Spain), S.L. no será responsable de los daños que los
                  mismos pudieran producir al Usuario.
                </p>
                <p>
                  Fallos tecnológicos: JNTL Consumer Health (Spain), S.L. ha concluido
                  todos los contratos necesarios para la continuidad de su Sitio
                  y realizará sus mejores esfuerzos para que el mismo no sufra
                  interrupciones, pero no puede garantizar la ausencia de fallos
                  tecnológicos, ni la permanente disponibilidad del Sitio y de
                  los servicios contenidos en él y, en consecuencia, no asume
                  responsabilidad alguna por los daños y perjuicios que puedan
                  generarse por la falta de disponibilidad y por los fallos en
                  el acceso ocasionados por desconexiones, averías, sobrecargas
                  o caídas de la red no imputables a JNTL Consumer Health (Spain), S.L.
                </p>
                <p> </p>
                <h2>Ley aplicable y Jurisdicción</h2>
                <p>
                  Para la resolución de todas las controversias o cuestiones
                  relacionadas con el presente sitio web o de las actividades en
                  él desarrolladas, será de aplicación la legislación española
                  siendo competentes para la resolución de todos los conflictos
                  derivados o relacionados con su uso los Juzgados y Tribunales
                  españoles competentes.
                </p>
                <p> </p>
                <h2>Duración y terminación</h2>
                <p>
                  La prestación del servicio del Sitio y los demás servicios
                  ofertados en el mismo tiene, en principio, una duración
                  indefinida. JNTL Consumer Health (Spain), S.L. podrá, no obstante, dar
                  por terminada o suspender la prestación del servicio del Sitio
                  y/o de cualquiera de los servicios en cualquier momento, sin
                  perjuicio de lo que se hubiere dispuesto al respecto en las
                  correspondientes Condiciones Particulares.
                </p>
                <p>
                  JNTL Consumer Health (Spain), S.L. se reserva, asimismo, el derecho de
                  modificar unilateralmente, en cualquier momento y sin previo
                  aviso, la presentación y condiciones del Sitio, así como los
                  servicios del mismo y las condiciones requeridas para su
                  utilización.
                </p>
                <p>
                  De conformidad con la Ley 34/2002, de 11 de julio, de
                  servicios de la sociedad de la información y de comercio
                  electrónico, se facilita la información general del
                  responsable de este sitio web: JNTL Consumer Health (Spain), S.L.
                  domiciliada en el Paseo de las Doce Estrellas 5-7, 28042
                  Madrid., inscrita en el Registro Mercantil de Madrid, T.
                  2.472, Gral. 1.821, Secc. 3ª, Libro Sdes., F. 95, H. 15.989,
                  Insc. 1.ª 29/10/69 , con N.I.F.: a-28/218675
                </p>
                <p>
                  Distribuidor de productos sanitarios y cosméticos, registrado
                  con los números 4260-PS y 363-C, respectivamente, y
                  supervisado por la Agencia Española del Medicamento.
                </p>
                <p>Este aviso legal se reviso por última vez el 01/02/2023</p>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinEspana"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuinespana"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UC4UKe2bJ7_abRLvHmWKSzzg"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
